import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import Seo from "../components/seo"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

const H1 = props => <h1 style={{ color: "tomato" }} {...props} />
const H2 = props => <h2 style={{ color: "saddlebrown", marginBottom: `10px` }} {...props} />

const components = {
  h1: H1,
  h2: H2,
  h3: (props) => {
      return (
        <h3 style={{ 
              color: "dimgray", 
              textAlign: "center", 
              marginTop: "20px" 
            }}
            {...props} 
        />
      )
  },
  h4: (props) => {
      return (
        <h4 style={{ 
              marginTop: "20px" 
            }}
            {...props} 
        />
      )
  }
}

const CasesPage = ({ data, location }) => {
  // const tableOfContents = useHeadings();
  // console.log("tableOfContents@CasePage", tableOfContents)
  const siteTitle = data.site.siteMetadata.title || `Title`
  const { allReference } = data
  const cases = data.allMdx.nodes
  if (cases.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All Cases" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} >
      <h1> 架空の症例集 </h1>
      <Seo title="架空の症例集" />

      {cases.map(article => {
        // const title = article.frontmatter.title || article.fields.slug
        return (
            <article
              className="post-list-item"
              itemScope
              itemType="http://schema.org/Article"
            >
            <MDXProvider components={components}>
              <MDXRenderer 
                localImages={article.frontmatter.embeddedImagesLocal} 
                references={allReference}
                itemProp="articleBody"
              >
                {article.body}
              </MDXRenderer>
            </MDXProvider>
            <hr />
            <footer> </footer>
            </article>
        )
      })}
    </Layout>
  )
}

export default CasesPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(filter: {frontmatter: {tags: {in: "架空の症例"}}}, sort: {order: DESC, fields: [frontmatter___date]}) {
      nodes {
        id
        excerpt
        body
        fields {
          slug
        }
        frontmatter {
          tags
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
        tableOfContents
      }
    }
    allReference {
      edges {
        node {
          key
          title
          authors
          journal
          date
          publicurl
          date
        }
      }
    }
  }
`
